/********************************************************************
* お問い合わせ　DOM操作
**********************************************************************/
import { showLoading, hideLoading } from "./../../module/loading";
import addSelectWrap from "./../../module/addSelectWrap";
import replaceAllBtn from "./../../module/replaceAllBtn";
import removeRequiredAttributes from "./../../module/removeRequiredAttributes";

const mypageDOMControl = () => {
    showLoading();

    // ボタンの置換
    const replaceBtn = () => {
        // メインのボタン
        const mainBtn = [
            '確認する',
            '送信する',
        ];
    
        // 戻るボタン
        const backBtn = [
            '戻る'
        ];
        
        replaceAllBtn(mainBtn, backBtn);
    }

    addEventListener('onSysAllContentLoaded', () => {
        addSelectWrap();
        replaceBtn();
        removeRequiredAttributes();
        hideLoading();
    });
}


export default mypageDOMControl;