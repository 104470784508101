/********************************************************************
* クーポンページ　DOM操作(mypageDOMControl.jsから呼び出される)
**********************************************************************/
const sysMypageReviewDOMControl = () => {
    // 利用済みクーポンを非表示にする
    const hideEndCoupon = () => {
        const targetList = document.querySelectorAll('.sysCoupon');
        if (!targetList.length) return;
        
        let count = 0;
        for (const target of targetList) {
            const status = target.querySelector('.sysCouponCount .detail');
            if (!status) continue;

            if (status.textContent.includes('利用済み')) {
                target.style.display = 'none';
                count++;
            }
        }
        
        // 全て利用済みの場合、メッセージを表示
        if (count === targetList.length) {
            const nav = document.querySelector('.sysMypageNav');
            if (!nav) return;

            const msg = document.createElement('div');
            msg.classList.add('sysCoupon');
            msg.textContent = '利用可能なクーポンはありません。';
            nav.parentNode.insertBefore(msg, nav);
        }
    }

    hideEndCoupon();
}


export default sysMypageReviewDOMControl;