// 共通
import addBodyId from "./module/addBodyId";
import removeUserAssets from "./module/removeUserAssets";
import wowInit from "./module/wowInit";
import adjustViewport from "./module/adjustViewport";
import smoothScroll from "./module/smoothScroll";
import toggleGnav from "./module/toggleGnav";
import headerChangeBg from "./module/headerChangeBg";
import showFloatingBtn from "./module/showFloatingBtn";
import accordion from "./module/accordion";
import outputCartData from "./module/outputCartData";
import fixFooterToBottom from "./module/fixFooterToBottom";
import checkUserLogin from "./module/checkUserLogin";
import addPageTitle from "./module/addPageTitle";

// トップページ
import topMvSlider from "./page/top/topMvSlider";
import topAboutSlider from "./page/top/topAboutSlider";
import topProductSlider from "./page/top/topProductSlider";
import loadFaq from "./page/top/loadFaq";
import topDOMControl from "./page/top/topDOMControl";

// 商品ページ
import itemDOMControl from "./page/sysItemDetail/itemDOMControl";
import itemSlider from "./page/sysItemDetail/itemSlider";

// カートページ
import sysBasketDOMControl from "./page/sysCart/sysBasketDOMControl";
import sysRegiDOMControl from "./page/sysCart/sysRegiDOMControl";

// ワンページカート　情報入力ページ
import opcDOMControl from "./page/sysOpcBody/opcDOMControl";

// マイページ
import mypageDOMControl from "./page/sysMypage/mypageDOMControl";

// 会員登録ページ
import registerDOMControl from "./page/sysMemberRegister/registerDOMControl";

// お問い合わせ
import inquiryDOMControl from "./page/form-inquiry/inquiryDOMControl";

// カテゴリ一覧ページ
import sysItemCategoryDOMControl from "./page/sysItemCategory/sysItemCategoryDOMControl";

// SCSS
import "../scss/style.scss";


// -------------START: テスト用のカスタムイベントを作成-------------

// 本番サーバー以外で動かす場合を想定しています。
// ドメインが「tiny-stand.aispr.jp」もしくは「tiny-stand.com」でない場合はテスト環境とみなします。
if (window.location.hostname !== 'tiny-stand.aispr.jp' && window.location.hostname !== 'tiny-stand.com') {
    const sysAllContentLoaded = new CustomEvent('onSysAllContentLoaded', {
        detail: {},
        bubbles: true,
        cancelable: true
    });
    window.addEventListener('load', () => {
        document.dispatchEvent(sysAllContentLoaded);
    });
}

// -------------END: テスト用のカスタムイベントを作成-------------

removeUserAssets();
addBodyId();
wowInit();
adjustViewport();
smoothScroll();
toggleGnav();
headerChangeBg();
showFloatingBtn();
accordion();
outputCartData();
fixFooterToBottom();
checkUserLogin();
addPageTitle();

// トップページ
if (document.body.id === 'top') {
    topMvSlider();
    topAboutSlider();
    topProductSlider();
    loadFaq();
    topDOMControl();

// 商品ページ
} else if (document.body.id === 'sysItemDetail') {
    itemDOMControl();
    itemSlider();

// カートページ
} else if (document.body.className === 'sysCart') {
    if (document.body.id === 'sysBasket') {
        sysBasketDOMControl();

    // レジページ (ログインせずに購入画面に進んだ場合のみ表示されるページ)
    } else if (document.body.id === 'sysRegi') {
        sysRegiDOMControl();
    }

// カート情報入力ページ
} else if (document.body.className === 'sysOpcBody') {
    opcDOMControl();

// マイページ
} else if (document.body.className === 'sysMypage') {
    mypageDOMControl();

// 会員登録ページ
} else if (document.body.className === 'sysMemberRegister') {
    registerDOMControl();

// お問い合わせ
} else if (document.body.className === 'form-inquiry') {
    inquiryDOMControl();

// カテゴリ一覧ページ（運用なし想定）
} else if (document.body.id === 'sysItemCategory') {
    sysItemCategoryDOMControl();
}
