/**
* 要素を移動させる
* @param {HTMLElement | string} from 移動元の要素もしくはセレクタ
* @param {HTMLElement | string} to 移動先の要素もしくはセレクタ
* @param {boolean} insertBefore 移動先の要素の前に移動するかどうか
 */
const teleportElement = (from, to, insertBefore = false) => {
    if (!from || !to) return;
    let fromEl, toEl;

    // 要素を取得する関数
    const getEl = (selector, errMsg = '') => {
        const el = document.querySelector(selector);
        if (!el) {
            if (errMsg) {
                console.error(errMsg);
            }
            return;
        }
        return el;
    }

    // 移動元の要素を取得
    if (from instanceof HTMLElement) {
        fromEl = from;
    } else if (typeof from === 'string') {
        fromEl = getEl(from);
    } else {
        console.error('移動元の要素はHTMLElementか文字列で指定してください');
        return;
    }

    // 移動先の要素を取得
    if (to instanceof HTMLElement) {
        toEl = to;
    } else if (typeof to === 'string') {
        toEl = getEl(to);
    } else {
        console.error('移動先の要素はHTMLElementか文字列で指定してください');
        return;
    }

    // 要素を移動
    if (fromEl && toEl) {
        if (!insertBefore) {
            toEl.appendChild(fromEl);
        } else {
            toEl.parentNode.insertBefore(fromEl, toEl);
        }
    }
}

export default teleportElement;