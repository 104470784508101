/**
 * 商品詳細・カート　配送時間オプションにクラスを追加
 */
const addScheduleClass = () => {
    const targetList = document.querySelectorAll('#sysSelections > div');
    if (!targetList.length) return;

    const addMap = [
        // レンタル商品
        {include: '【1〜6台ご注文のお客様】お届け便時間指定', class: ['time', 'is_1']},
        {include: '【7〜20台ご注文のお客様】お届け便・お引き取り便時間指定', class: ['time', 'is_2']},
        // 販売商品
        {include: '【1〜6台ご注文のお客様】配送時間指定', class: ['time', 'is_1']},
        {include: '【7〜20台ご注文のお客様】配送時間指定', class: ['time', 'is_2']},
    ];

    targetList.forEach(target => {
        for (let i = 0; i < addMap.length; i++) {
            const add = addMap[i];
            if (target.textContent.includes(add.include)) {
                target.classList.add(...add.class);
            }
        }
    });
}

export default addScheduleClass;