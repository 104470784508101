/********************************************************************
* 情報入力ページ　DOM操作
**********************************************************************/
import { showLoading, hideLoading } from "./../../module/loading";
import addClassBtn from "./../../module/addClassBtn";
import wrapInputBtn from "./../../module/wrapInputBtn";
import addSelectWrap from "./../../module/addSelectWrap";

const opcDOMControl = () => {
    showLoading();

    // ボタンの置換 (HTML構造を変更すると編集機能が動作しなくなるため、wrapInputBtn()を使わない)
    const replaceBtn = () => {
        const targetList = document.querySelectorAll('input[type="button"]');
        if (!targetList.length) return;

        // ボタンにクラスを追加
        for (let i = 0; i < targetList.length; i++) {
            const target = targetList[i];
            const parent = target.parentNode;
            
            // メインボタン（確定ボタン）
            if (target.value === '注文を確定する') {
                parent.classList.add('bl_btnWrap'); //親要素にclassを追加
                addClassBtn(target, true);
                
            // メインボタン（確定ボタン(width:100%)）
            } else if (target.value === '注文内容を確定し、お支払い手続きへ') {
                parent.classList.add('bl_btnWrap'); //親要素にclassを追加
                addClassBtn(target, true, false, ['is_w100']);

            // メインボタン
            } else if (target.value === '注文内容を確認する' || target.value === '次へ') {
                addClassBtn(target, true);

            // 削除ボタン
            } else if (target.value === '削除') {
                target.classList.add('el_deleteBtn');
            }
        }   
    }

    // 戻るボタンの置換
    const replaceBackBtn = () => {
        const target = document.querySelector('.opcOrderBackToBasket input');
        if (!target) return;

        wrapInputBtn(target);
        addClassBtn(target, false, true);
    }

    // 商品名にRENTALが含まれる場合は追加するエリアを非表示にする
    const hideAdditionalAreaIfRental = () => {
        const itemList = document.querySelectorAll('.opcItem');
        if (!itemList.length) return;
        
        for (const item of itemList) {
            // 商品名を取得
            const title = item.querySelector('.opcItem_itemName');
            if (!title) continue;
            
            // 追加するエリアを取得
            const target = item.querySelector('.opcItem_deliveryAddressItemGroup');
            if (!target) continue;

            // エリアを非表示
            if (title.textContent.includes('RENTAL')) {
                target.classList.add('is_hide');
            }
        }
    }

    // amazon pay inputにチェックを入れたら自動的にAmazon Pay画面に遷移 (Amazon Payボタンをクリック)
    const clickAmazonPayBtn = () => {
        const trigger = document.querySelector('.opcPaymentMethod_edit:has(#amazonPayPaymentMethodLoginButtonPayAndShip) input'); //Amazon Pay input
        const target = document.querySelector('#amazonPayPaymentMethodLoginButtonPayAndShip'); //Amazon Payボタン
        if (!trigger || !target) return;

        trigger.addEventListener('change', () => {
            if (trigger.checked) {
                target.click();
            }
        });
    }

    // 実行関数
    const doWhenDOMChenged = () => {
        addSelectWrap();
        replaceBtn();
        replaceBackBtn();
        hideAdditionalAreaIfRental();
        clickAmazonPayBtn();
        hideLoading();
    }

    // DOMが変更されたら実行
    const DOMObserve = () => {
        // 対象とするノードを取得
        const target = document.querySelector('#sysMain');

        // オブザーバの設定
        const config = {
            childList: true,
            subtree: true
        };

        // 監視を開始
        const observer = new MutationObserver(doWhenDOMChenged);
        observer.observe(target, config);
    }

    // 初期実行
    addEventListener('onSysAllContentLoaded', () => {
        doWhenDOMChenged();
    });

    DOMObserve();
}

export default opcDOMControl;
