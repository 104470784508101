/**
 * ページ内の全てのボタンに以下のクラスを追加
 *      メインのボタン: .el_btn.is_main
 *      戻るボタン: .el_btn.is_back
 *      削除ボタン: .el_deleteBtn
 *      それ以外のボタン: .el_btn
 *  inputボタン（削除ボタン以外）は更に.bl_btnWrapでラップします。
 * @param {String[]} mainBtn メインのボタン（背景がオレンジ色）のvalue
 * @param {String[]} backBtn 戻るボタンのvalue
 * @param {String[]} deleteBtn 削除ボタンのvalue
 * @param {String[]} exclude 除外するボタンのvalue
 */

import addClassBtn from "./addClassBtn";
import wrapInputBtn from "./wrapInputBtn";

const replaceAllBtn = (mainBtn = [], backBtn = [], deleteBtn = [], exclude = []) => {
    /**
     * 置換関数
     * @param {NodeList} targetList 置換対象のノードリスト
     */
    const replace = (targetList) => {
        if (!targetList.length) return;

        for (let i = 0; i < targetList.length; i++) {
            const target = targetList[i];
            
            // テキストを取得
            let value;
            if (target.tagName === 'INPUT') {
                value = target.value;
            } else if (target.tagName === 'BUTTON') {
                value = target.textContent;
            }

            // 除外するボタンに該当する場合はスキップ
            if (exclude.includes(value)) continue;

            // メインボタンの置換
            if (mainBtn.includes(value)) {
                addClassBtn(target, true);
                
            // 戻るボタンの置換
            } else if (backBtn.includes(value)) {
                addClassBtn(target, false, true);

            // 削除ボタンの置換
            } else if (deleteBtn.includes(value)) {
                target.classList.add('el_deleteBtn');
            
            // それ以外のボタンの置換
            } else {
                addClassBtn(target);
            }

            // inputボタンかつ削除ボタンでない場合はラップ
            if (target.tagName === 'INPUT' && !deleteBtn.includes(value)) {
                wrapInputBtn(target);
            }
        }
    }

    // 実行
    replace(document.querySelectorAll('input[type="button"]'));
    replace(document.querySelectorAll('input[type="submit"]'));
    replace(document.querySelectorAll('button'));
}

export default replaceAllBtn;