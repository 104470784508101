/**
 * コンテンツが画面の高さより小さければフッターを下部に固定
 */
const fixFooterToBottom = () => {
    const footer = document.querySelector('.js_footer');
    if (!footer) return;

    const doWhenLoaded = () => {
        const winH = window.innerHeight; //画面の高さ
        const contsH = document.body.clientHeight; //コンテンツの高さ

        // classを付与するかどうか判定
		if (contsH < winH) {
			footer.classList.add('is_fixed');
            // console.log('add');
		} else {
            footer.classList.remove('is_fixed');
            // console.log('remove');
		}
    }

    // 要素のサイズ変更を検知
    const resizeObserve = () => {
        const target = document.body; //対象ノード
        const observer = new ResizeObserver(doWhenLoaded); //オブザーバーを生成
        observer.observe(target); //監視を開始
    }

    window.addEventListener('load', doWhenLoaded);
    resizeObserve();
}

export default fixFooterToBottom;