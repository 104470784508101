/**
 * 商品販売ページ　DOM操作
 * itemDOMControl.jsのonSysAllContentLoaded内で実行されます。
 */

import replaceText from "./../../module/replaceText";

const buyDOMControl = () => {
    // 配送時間のテキスト置換
    const replaceScheduleText = () => {
        const target = document.querySelectorAll('#sysSelections > div');
        if (!target.length) return;

        target.forEach(item => {
            replaceText(item, [
                {before: '：', after: ''},
                {before: '【1〜6台ご注文のお客様】配送時間指定', after: '<span class="un_subtitle">1〜6台ご注文のお客様</span><span class="selectionTitle">配送時間指定</span>'},
                {before: '【7〜20台ご注文のお客様】配送時間指定', after: '<span class="un_subtitle">7〜20台ご注文のお客様</span><span></span>'},
            ]);
        });
    }

    replaceScheduleText();
}

export default buyDOMControl;