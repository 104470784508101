/********************************************************************
* カートの数量をアイコン左上に出力
**********************************************************************/
import outputAishipData from './outputAishipData';

const outputCartData = () => {
    // 対象とするノードを取得
    const target = document.querySelector('.js_aishipData.js_cart .sysContent');
    if (!target) return;

    // アクティブクラスを追加する関数
    const addClass = (el) => {
        el.classList.add('is_active');
    }

    // 対象のDOMが変更された時に実行される関数
    const doWhenDOMChenged = () => {
        // カート内の商品数の要素
        const fromEl = document.querySelector('.sysCartInfoItemCount dd span');

        // 要素が存在しない、もしくは商品数が0であれば終了
        if (!fromEl || fromEl.textContent === '0') return;
        
        // テキストを出力、アクティブクラスを追加
        outputAishipData(fromEl, '.js_cartCount', false, addClass);
    }

    // オブザーバインスタンスを作成
    const observer = new MutationObserver(() => {
        doWhenDOMChenged();
    });

    // オブザーバの設定
    const config = {
        childList: true,
        subtree: true
    };

    // 対象ノードとオブザーバの設定を渡す
    observer.observe(target, config);
}

export default outputCartData;