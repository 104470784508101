<script setup>	
import { ref, onUpdated } from 'vue';
import axios from 'axios';
import smoothScroll from '../module/smoothScroll';

const el = ref(null);

// アコーディオンのデータ
const faq = ref();

// アコーディオンのデータ取得
const getFaqData = () => {
    const url = '/data/faq.json';
    axios.get(url).then(res => {
        faq.value = res.data;
        // console.log(faq.value);
    }).catch(error => {
        console.log(error);
    });
}
getFaqData();

/**
 * アコーディオン開閉処理
 * @param trigger トリガー
 * @param child 子アコーディオンであるかどうか
 */
const accordion = (trigger, child = false) => {
    const target = trigger.nextElementSibling; //アコーディオン中身(トリガーの次の要素)
    const targetH = target.scrollHeight; //アコーディオン中身の高さ

    // アクティブクラスの付与/削除
    trigger.classList.toggle('is_active');
    target.classList.toggle('is_active');

    // アコーディオン中身の高さ制御
    if (target.classList.contains('is_active')) {
        target.style.height = `${targetH}px`;
        if (child) {
            toggleParentHeight(target, targetH, 'open');
        }
    
    } else {
        target.style.height = '0';
        if (child) {
            toggleParentHeight(target, targetH, 'close');
        }
    }
}

// 親アコーディオンの高さ制御
const toggleParentHeight = (target, targetH, type = 'open') => {
    const parent = target.closest('.bl_faq_body');
    const parentH = parent.scrollHeight;

    if (type === 'open') {
        parent.style.height = `${parentH + targetH}px`;
    } else if (type === 'close') {
        parent.style.height = `${parentH - targetH}px`;
    }
}

// アコーディオン内のスムーススクロールの設定
onUpdated(() => {
    if (!el.value) return;
    smoothScroll(el.value);
});
</script>

<template>
<div class="bl_faq" ref="el" v-if="faq">
    <div class="bl_faq_item" v-for="val in faq">
        <div class="bl_faq_head" @click="accordion($event.currentTarget)">
            <div class="bl_faq_head_text" v-html="val.cat"></div>
            <div class="bl_faq_icon"></div>
        </div>
        <div class="bl_faq_body">
            <div class="bl_faq_body_content">
                <div class="bl_accordion" v-for="val2 in val.contents">
                    <a class="bl_accordion_head" @click="accordion($event.currentTarget ,true)">
                        <div class="bl_accordion_head_text" v-html="val2.q"></div>
                        <div class="bl_accordion_icon"></div>
                    </a>
                    <div class="bl_accordion_body">
                        <div class="bl_accordion_body_content" v-html="val2.a"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>