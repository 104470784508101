// セレクトボックスのラップを追加
const addSelectWrap = () => {
    const targetList = document.querySelectorAll('select');
    if (!targetList.length) return;

    for (let i = 0; i < targetList.length; i++) {
        const target = targetList[i];
        if (target.parentNode.classList.contains('el_select')) continue;

        const selectWrap = document.createElement('div');
        selectWrap.className = 'el_select';
        target.parentNode.insertBefore(selectWrap, target);
        selectWrap.appendChild(target);
    }
}

export default addSelectWrap;