import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

const topAboutSlider = () => {
    const swiper = new Swiper('.js_top_about_slider', {
        modules: [Navigation],
        slidesPerView: 'auto',
        navigation: {
            nextEl: '.js_top_about_slider_arrow.is_next',
            prevEl: '.js_top_about_slider_arrow.is_prev',
        },
        breakpoints: {
            768: {
                slidesPerView: 3,
            }
        }
    });
}

export default topAboutSlider;
