/********************************************************************
* マイページ　DOM操作
**********************************************************************/
import { showLoading, hideLoading } from "./../../module/loading";
import addSelectWrap from "./../../module/addSelectWrap";
import replaceAllBtn from "./../../module/replaceAllBtn";
import removeRequiredAttributes from "./../../module/removeRequiredAttributes";
import sysMypageReviewDOMControl from "./sysMypageReviewDOMControl";
import sysMypageLoginDOMControl from "./sysMypageLoginDOMControl";
import sysMypageWithdrawalConfirmDOMControl from "./sysMypageWithdrawalConfirmDOMControl";

const mypageDOMControl = () => {
    showLoading();

    // ボタンの置換
    const replaceBtn = () => {
        // メインのボタン
        const mainBtn = [
            'ログイン',
            'マイページトップ',
            '確認メールを受け取る',
            '登録',
            '登録する',
            '更新する',
            '退会する'
        ];
    
        // 戻るボタン
        const backBtn = [
            '戻る',
            '退会しない'
        ];

        // 置換しない
        const exclude = [
            '住所検索'
        ];

        replaceAllBtn(mainBtn, backBtn, [], exclude);
    }


    addEventListener('onSysAllContentLoaded', () => {
        addSelectWrap();
        replaceBtn();
        removeRequiredAttributes();

        // クーポンを確認する
        if (document.body.id === 'sysMypageReview') {
            sysMypageReviewDOMControl();

        // ログインページ
        } else if (document.body.id === 'sysMypageLogin') {
            sysMypageLoginDOMControl();
        }

        // 退会確認ページ
        if (document.body.id === 'sysMypageWithdrawalConfirm') {
            sysMypageWithdrawalConfirmDOMControl();
        }

        hideLoading();
    });
}


export default mypageDOMControl;