/********************************************************************
* レジページ　DOM操作
**********************************************************************/
import { showLoading, hideLoading } from "./../../module/loading";
import replaceAllBtn from "./../../module/replaceAllBtn";

const cartDOMControl = () => {
    showLoading();

    // ボタンの置換
    const replaceBtn = () => {
        // メインのボタン
        const mainBtn = [
            'ログイン',
            '会員情報入力へ',
            'ご注文手続きへ',
        ];
    
        // 戻るボタン
        const backBtn = [
            '戻る',
        ];
        
        replaceAllBtn(mainBtn, backBtn);
    }

    addEventListener('onSysAllContentLoaded', () => {
        replaceBtn();
        hideLoading();
    });
}


export default cartDOMControl;