/********************************************************************
* カートページ　DOM操作
**********************************************************************/
import { showLoading, hideLoading } from "./../../module/loading";
import teleportElement from "./../../module/teleportElement";
import addSelectWrap from "./../../module/addSelectWrap";
import replaceAllBtn from "./../../module/replaceAllBtn";
import addClassBtn from "./../../module/addClassBtn";
import replaceText from "./../../module/replaceText";
import addScheduleClass from "./../../module/addScheduleClass";

const cartDOMControl = () => {
    showLoading();

    // ボタンの置換
    const replaceBtn = () => {
        // メインのボタン
        const mainBtn = [
            'ご注文手続きへ',
            '送信する',
        ];
    
        // 戻るボタン
        const backBtn = [
            '戻る',
        ];
    
        // 削除ボタン
        const deleteBtn = [
            '削除'
        ];

        // 置換しない
        const exclude = [
            // クーポン関係のinputボタンをラップすると編集機能が動作しなくなるため、以下は個別に置換します。
            'クーポンを確認する',
            '適用する',
            'キャンセル'
        ];
        
        replaceAllBtn(mainBtn, backBtn, deleteBtn, exclude);
    }

    // Amazon Pay利用の場合に自動的に付与される送信ボタンのスタイルを削除
    const deleteStyleSubmitBtn = () => {
        const targetList = document.querySelectorAll('input[value="ご注文手続きへ"]');
        if (!targetList.length) return;

        for (const target of targetList) {
            target.removeAttribute('style');
        }
    }

    // 商品合計のテキスト置換
    const replaceTotal = () => {
        const target = document.querySelector('.sysTotalTxt');
        if (!target) return;

        replaceText(target, [
            {before: '商品合計：', after: '<span class="preffix">商品合計</span>'},
            {before: '円', after: '<span class="en">円</span>'},
            {before: '（税込）', after: '<span class="zeikomi">（税込）</span>'}
        ]);
    }

    // クーポンを確認するボタンを移動＆クラス追加
    const replaceCouponBtn = () => {
        const target = document.querySelector('.sysConfirmCoupon'); //移動させる要素
        const toAfter = document.querySelector('#openCouponEditDialog'); //移動先の次の要素
        if (!target || !toAfter) return;

        teleportElement(target, toAfter, true); //移動
        target.children[0].classList.add('el_btn'); //クラス追加
    }

    // クーポンを適用する画面のボタンの置換
    const replaceModalCouponBtn = () => {
        const targetParent = document.querySelector('.basketCouponEditDialog_buttonGroup');
        if (!targetParent) return;

        for (const target of targetParent.children) {
            if (target.value === '適用する') {
                addClassBtn(target, true);
            } else {
                addClassBtn(target);
            }
        }
    }

    // 商品ヘッダーをラッパーで囲む
    const formatItemHeader = () => {
        const targetList = document.querySelectorAll('.sysCartPackageDetailList .sysBox');
        if (!targetList.length) return;

        targetList.forEach(target => {
            // 要素を取得
            const img = target.querySelector('.sysProductImg');
            const head = target.querySelector('.sysProduct');
            const insert = target.querySelector('hr');

            // ラッパーを作成
            const wrap = document.createElement('div');
            wrap.classList.add('itemHead');

            // 要素を追加
            wrap.appendChild(img);
            wrap.appendChild(head);

            // 挿入
            insert.before(wrap);
        });
    }

    // 商品単価のテキスト置換
    const replaceRetailPrice = () => {
        const targetList = document.querySelectorAll('.sysItemRetailPrice');
        if (!targetList.length) return;

        targetList.forEach(target => {
            replaceText(target, [
                {before: '商品単価：', after: '<span class="preffix">商品単価</span>'},
                {before: '円', after: '<span class="en">円</span>'},
            ]);
        });
    }

    // 配送時間のテキスト置換
    const replaceScheduleText = () => {
        const targetList = document.querySelectorAll('.sysSubTotalArea');
        if (!targetList.length) return;

        for (let i = 0; i < targetList.length; i++) {
            const target = targetList[i];

            // レンタル商品かどうか判定
            if (!target.textContent.includes('レンタル開始日：')) continue;
            
            // ラップ、文字列の置換
            replaceText(target, [
                {before: new RegExp('延長(.+)円）'), after: ''}, //延長を削除
                {before: 'レンタル開始日：', after: '<div class="schedule">レンタル開始日：'}, //開始タグ
                {before: new RegExp('返却日：(.+)'), after: 'ご返却予定日：$1</div>'}, //終了タグ（返却日の文字列置換も）
            ]);
            
            // 1行ごとにHTMLタグで囲む
            const dateEl = target.querySelector('.schedule'); //wrapperを取得
            const replaceMap = [
                //search: 検索する文字列、class: 追加するクラス名
                {search: 'レンタル開始日', class: 'start'},
                {search: 'お届け予定日', class: 'reach'},
                {search: 'ご利用日', class: 'use'},
                {search: 'ご返却予定日', class: 'return'},
            ];
            for (let j = 0; j < replaceMap.length; j++) {
                const map = replaceMap[j];
                const pattern = new RegExp(`${map.search}：(.+)<br>`); //正規表現パターン
                const replace = `<div class="${map.class}"><span>${map.search}</span><span>$1</span></div>`; //置換後の文字列
                replaceText(dateEl, [
                    {before: new RegExp(pattern), after: replace},
                ]);
            }
        }
    }

    // 小計のテキスト置換
    const replaceTotalText = () => {
        const targetList = document.querySelectorAll('.sysSubTotalArea');
        if (!targetList.length) return;

        targetList.forEach(target => {
            // 小計置換
            replaceText(target, [
                {before: new RegExp('<span class="sysCartAttention">(.+)通常送料）'), after: '<div class="total">$1通常送料）</div>'}, //ラップ
                {before: '小計：', after: '<span class="preffix">小計</span>'},
                {before: '円', after: '<span class="en">円</span>'},
            ]);
        });
    }

    addEventListener('onSysAllContentLoaded', () => {
        addSelectWrap();
        replaceBtn();
        deleteStyleSubmitBtn();
        replaceTotal();
        replaceCouponBtn();
        replaceModalCouponBtn();
        formatItemHeader();
        replaceRetailPrice();
        addScheduleClass();
        replaceScheduleText();
        replaceTotalText();
        hideLoading();
    });
}


export default cartDOMControl;