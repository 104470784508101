/**
 * グロナビ開閉
*/
import getMq from './getMq';
const toggleGnav = () => {
    const trigger = document.querySelector('.js_menubar'); //メニューバー
    const target = document.querySelector('.js_gnav'); //グロナビ
    if (!trigger || !target) return;
    
    const closeTrigger = target.querySelector('.js_close'); //閉じるボタン

    /**
     * グロナビ展開後の高さを設定
    */
    const setTargetH = () => {
        const endHeight = `${window.innerHeight}px`; //画面の高さ

        // 展開後の高さをカスタムプロパティに設定
        target.style.setProperty('--end-height', endHeight);
    }

    setTargetH();
    window.addEventListener('resize', setTargetH);
    

    /**
     * グロナビ出現時にbodyを固定
     * @var {string} type - 開く時は'open'、閉じる時は'close'
    */
    const fixBody = (type = 'open') => {
        const body = document.body;
        const duration = 200; //グロナビ出現にかかる時間
        
        // 固定
        if (type === 'open') {
            const scroll = window.scrollY;
            setTimeout(() => {
                body.classList.add('is_fixed');
                body.style.top = `${-scroll}px`;
            }, duration);

        // 固定を解除
        } else if (type === 'close') {
            const scroll = -parseFloat(body.style.top);
            if (!body.classList.contains('is_fixed')) return;
            body.classList.remove('is_fixed');
            body.style.top = '';
            window.scrollTo(0, scroll);
        }
    }
    
    /**
     * 開閉
    */
    // 開く
    const open = () => {
        target.classList.add('is_show');
        fixBody('open');
    }

    // 閉じる
    const close = () => {
        target.classList.remove('is_show');
        fixBody('close');
    }

    // 開閉関数をクリックイベントに登録
    trigger.addEventListener('click', open);
    closeTrigger.addEventListener('click', close);

    // グロナビにページ内リンクがある場合
    const anchorLink = () => {
        // グロナビ内のhref="#"のaタグを取得
        const anchorList = target.querySelectorAll('a[href*="#"]');
        if (!anchorList || anchorList.length === 0) return;

        // クリックイベントを追加
        anchorList.forEach(anchor => {
            anchor.addEventListener('click', () => {
                // PCでは発火しない
                const mq = getMq();
                if (mq === 'lg' || mq === 'xl') return;
                close(); //グロナビを閉じる
            });
        });
    }
    anchorLink();

    
    /**
     * グロナビを開いた状態でメディアクエリが変わった時lg以上なら閉じる
    */
    const closeOnMq = () => {
        const mq = getMq();
        if (mq === 'lg' || mq === 'xl') close();
    }
    window.addEventListener('resize', closeOnMq);
}

export default toggleGnav