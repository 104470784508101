/********************************************************************
* ヘッダー背景色変更
**********************************************************************/
import getMq from './getMq';

const headerChangeBg = () => {
    const header = document.querySelector('.js_header');
    if (!header) return;
    
    const mq = getMq();

    // スクロールした時に呼ばれる関数
    const doWhenScroll = () => {
        const targetPos = 100; //発火位置
        const scroll = window.scrollY;
        if (scroll > targetPos) {
            header.classList.add('is_bg');
        } else {
            header.classList.remove('is_bg');
        }
    }

    // トップページかつタブレット以下であればスクロールアクションを実行
    if (document.body.id === 'top' && mq !== 'lg' && mq !== 'xl') {
        window.addEventListener('scroll', doWhenScroll);

    // それ以外のページは常に背景色を適用
    } else {
        header.classList.add('is_bg');
    }

}

export default headerChangeBg;