/**
 * bodyにIDがなければパスの最後の文字列をIDに追加
 */
const addBodyId = () => {
    if (document.body.id) return;

    let path = window.location.pathname.split('/'); //パスを配列に変換
    path = path.filter((str) => !str.includes('index')); //indexを含む要素を削除
    const id = path[path.length - 1].replace('.html', ''); //最後の要素を取得（.htmlは削除）
    document.body.id = id; //bodyにidを追加
    // console.log(document.body.id);
}

export default addBodyId;
