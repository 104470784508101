/**
 * inputボタンをラップ (疑似クラスで矢印を表示するため)
 * @param {Element} el - 置換対象の要素
 */
const wrapInputBtn = (el) => {
    if (!el) return;
    if (el.parentElement.classList.contains('bl_btnWrap')) return;
    el.outerHTML = "<div class='bl_btnWrap'>" + el.outerHTML + "</div>";
}

export default wrapInputBtn;
