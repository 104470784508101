/**
 * テキスト置換
 * @param {Element} target 置換対象の要素
 * @param {Array} replaceMap 置換マップ
 *      @param {string} replaceMap[i].before 置換前のテキスト
 *      @param {string} replaceMap[i].after 置換後のテキスト
 */
const replaceText = (target, replaceMap) => {
    replaceMap.forEach(replace => {
        target.innerHTML = target.innerHTML.replace(replace.before, replace.after);
    });
}

export default replaceText;