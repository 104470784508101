/********************************************************************
* フローティングバナー表示
**********************************************************************/
import getDevice from './getDevice';

const showFloatingBtn = () => {
    const target = document.querySelector('.js_floatingBtn');
    if (!target) return;

    //TOPから何px地点で発火するか
    let offset = 0;
    if (getDevice() === 'sp') {
        offset = 300;
    }

    // スクロールしたら発火する関数
    const doWhenScroll = () => {
        if (window.scrollY >= offset) {
            target.classList.add('is_show');
        } else {
            target.classList.remove('is_show');
        }
    }

    doWhenScroll(); //初期実行
    window.addEventListener('scroll', doWhenScroll);
}

export default showFloatingBtn;

