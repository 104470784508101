/********************************************************************
* 会員登録　DOM操作
**********************************************************************/
import { showLoading, hideLoading } from "./../../module/loading";
import addSelectWrap from "./../../module/addSelectWrap";
import replaceAllBtn from "./../../module/replaceAllBtn";
import removeRequiredAttributes from "./../../module/removeRequiredAttributes";

const registerDOMControl = () => {
    showLoading();

    // ボタンの置換
    const replaceBtn = () => {
        // メインのボタン
        const mainBtn = [
            '登録内容を確認する',
            '登録する',
        ];
    
        // 戻るボタン
        const backBtn = [
            '戻る',
        ];
    
        // 置換しない
        const exclude = [
            '住所検索'
        ];
        
        replaceAllBtn(mainBtn, backBtn, [], exclude);
    }

    addEventListener('onSysAllContentLoaded', () => {
        addSelectWrap();
        replaceBtn();
        removeRequiredAttributes();
        hideLoading();
    });
}


export default registerDOMControl;