/**
 * 商品レンタルページ　DOM操作
 * itemDOMControl.jsのonSysAllContentLoaded内で実行されます。
 */

import teleportElement from "./../../module/teleportElement";
import replaceText from "./../../module/replaceText";

const rentalDOMControl = () => {
    // 自動出力される要素を指定の場所に移動
    const outputData = () => {
        teleportElement('select[name="rental_extend_date"]', '.js_tarm');
        teleportElement('.sysRentalSchedules', '.js_calendar');
        teleportElement('.sysRentalReachDay', '.js_reachDate');
        teleportElement('.sysRentalReturnDay', '.js_returnDate');
        teleportElement('#sysSelections', '.js_schedule');
        teleportElement('#sysSubmit', '.js_submit');
    }

    // イベント期間の選択肢置換
    const replaceRentalExtendDate = () => {
        const target = document.querySelectorAll('select[name="rental_extend_date"] option');
        if (!target.length) return;

        for (let i = 0; i < target.length; i++) {
            const option = target[i];
            option.textContent = `${i+1}日間`;
        }
    }

    // カレンダーのテキスト置換
    const replaceCalendarText = () => {
        const observeEl = document.querySelector('.sysRentalSchedulesCalendarArea');
        if (!observeEl) return;

        // DOMが変更された時に発火する関数 (前月、翌月の変更を検知)
        const doWhenDOMChanged = () => {
            const target = document.querySelectorAll('.sysRentalSchedulesCalendar th');
            if (!target.length) return;

            const replaceMap = [
                { before: '日', after: 'SUN'},
                { before: '月', after: 'MON'},
                { before: '火', after: 'TUE'},
                { before: '水', after: 'WED'},
                { before: '木', after: 'THU'},
                { before: '金', after: 'FRI'},
                { before: '土', after: 'SAT'},
            ];

            for (let i = 0; i < target.length; i++) {
                const item = target[i];
                item.textContent = item.textContent.replace(replaceMap[i].before, replaceMap[i].after);
            }
        }

        // カレンダーの変更を監視
        const observe = () => {
            const observer = new MutationObserver(doWhenDOMChanged);
            const config = {
                childList: true,
            };
            observer.observe(observeEl, config);
        }

        doWhenDOMChanged(); // 初回実行
        observe(); // 監視開始
    }

    // 配送時間のテキスト置換
    const replaceScheduleText = () => {
        const target = document.querySelectorAll('#sysSelections > div');
        if (!target.length) return;

        target.forEach(item => {
            replaceText(item, [
                {before: '：', after: ''},
                {before: '【1〜6台ご注文のお客様】お届け便時間指定', after: '<span class="un_subtitle">1〜6台ご注文のお客様</span>'},
                {before: '【7〜20台ご注文のお客様】お届け便・お引き取り便時間指定', after: '<span class="un_subtitle">7〜20台ご注文のお客様</span>'},
            ]);
        });
    }
    

    outputData();
    replaceRentalExtendDate();
    replaceCalendarText();
    replaceScheduleText();
}

export default rentalDOMControl;