/**
 * ユーザー用アセットの読み込みを削除
 */
const removeUserAssets = () => {
    // CSSとJSのリンク要素を取得
    const styleLinks = document.querySelectorAll('link[rel="stylesheet"]');
    const scriptTags = document.querySelectorAll('script[src]');

    // 削除するCSS
    const removeStyles = [
        'user.css',
        'common.css',
        'parts.css',
        'material.min.css',
        'rental.css',
        'cart.css',
    ];

    // 削除するJS
    const removeScripts = [
        '/js/user.js',
    ];

    // CSSリンクの処理
    for (let i = 0; i < styleLinks.length; i++) {
        const link = styleLinks[i];
        if (removeStyles.find((remove) => link.href.indexOf(remove) !== -1)) {
            link.remove();
        }
    }
    

    // スクリプトタグの処理
    for (let i = 0; i < scriptTags.length; i++) {
        const script = scriptTags[i];
        if (removeScripts.find((remove) => script.src.indexOf(remove) !== -1)) {
            script.remove();
        }
    }
}

export default removeUserAssets;
