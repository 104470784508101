import Swiper from 'swiper';
import { Autoplay, EffectFade } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';

const topProductSlider = () => {
    const swiper = new Swiper('.js_product_slider', {
        modules: [Autoplay, EffectFade],
        effect: 'fade',
        speed: 1000,
        loop: true,
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
        },
        breakpoints: {
            768: {
                effect: 'slide',
                slidesPerView: 3,
            }
        }
    });
}

export default topProductSlider;
