/**
 * ボタンにクラスを追加
 * @param {Element} el - 置換対象の要素
 * @param {boolean} isMain - メインボタンかどうか
 * @param {boolean} isBack - 戻るボタンかどうか
 * @param {string[]} addClass - 追加するクラス
 */
const addClassBtn = (el, isMain = false, isBack = false, addClass = []) => {
    if (!el) return;
    if (el.classList.contains('el_btn')) return;

    // クラスを追加
    el.classList.add('el_btn');

    // メインボタンの場合
    if (isMain) {
        el.classList.add('is_main');
    }

    // 戻るボタンの場合
    if (isBack) {
        el.classList.add('is_back');
    }

    // 追加クラスがある場合
    if (addClass.length) {
        addClass.forEach(cls => el.classList.add(cls));
    }
}

export default addClassBtn;