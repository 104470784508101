/********************************************************************
* 商品詳細ページ　スライダー
**********************************************************************/
import getDevice from "./../../module/getDevice";
import Swiper from 'swiper';
import { Autoplay, EffectFade, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';

const itemSlider = () => {
    const el = document.querySelector('.sysItemImages');
    if (!el) return;

    let swiper; //swiperインスタンス
    let pagination; //ページネーション要素
    const wrapper = el.querySelector('.sysImageList'); //ラッパー要素
    const slideList = wrapper.querySelectorAll('li'); //スライド要素

    // スライダーのオプション
    const options = {
        modules: [EffectFade, Autoplay, Pagination],
        effect: 'fade',
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
    }

    // スライダーを生成
    const add = () => {
        if (swiper) return;

        // スライダーのクラスを追加
        el.classList.add('swiper');
        wrapper.classList.add('swiper-wrapper');
        slideList.forEach(slide => {
            slide.classList.add('swiper-slide');
        });

        // ページネーション要素を追加
        pagination = document.createElement("div");
        pagination.classList.add('swiper-pagination');
        el.appendChild(pagination);

        // swiperインスタンスを生成
        swiper = new Swiper(el, options);
    }

    // スライダーを破棄
    const remove = () => {
        if (!swiper) return;

        // スライダーのクラスを削除
        el.classList.remove('swiper');
        wrapper.classList.remove('swiper-wrapper');
        slideList.forEach(slide => {
            slide.classList.remove('swiper-slide');
        });

        // ページネーション要素を削除
        pagination.remove();

        // スライダーを破棄
        swiper.destroy(true, true);
        swiper = null;
    }

    // 実行関数
    const init = () => {
        if (getDevice() === 'sp') {
            add();

        } else {
            remove();
        }
    }

    init(); //初期実行

    // リサイズ時に実行
    window.addEventListener('resize', () => {
        init();
    });
}

export default itemSlider;