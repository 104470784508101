/**
 * aishipシステムで出力されるデータを取得して特定の要素に出力
 * @param {string | HTMLElement} from 取得元の要素もしくはセレクタ
 * @param {string | HTMLElement} to 出力先の要素もしくはセレクタ
 * @param {boolean} href hrefを取得するかどうか
 * @param {function} callback コールバック関数
 */

const outputAishipData = (from, to, href = false, callback = null) => {
    if (!from || !to) return;
    let fromEl, toEl;

    // 要素を取得する関数
    const getEl = (selector, errMsg = '') => {
        const el = document.querySelector(selector);
        if (!el) {
            if (errMsg) {
                console.error(errMsg);
            }
            return;
        }
        return el;
    }

    // 取得元の要素を取得
    if (from instanceof HTMLElement) {
        fromEl = from;
    } else if (typeof from === 'string') {
        fromEl = getEl(from);
    } else {
        console.error('取得元の要素はHTMLElementか文字列で指定してください');
        return;
    }

    // 出力先の要素を取得
    if (to instanceof HTMLElement) {
        toEl = to;
    } else if (typeof to === 'string') {
        toEl = getEl(to);
    } else {
        console.error('出力先の要素はHTMLElementか文字列で指定してください');
        return;
    }

    if (!fromEl || !toEl) return;
    
    // 実行
    if (!href) {
        toEl.innerHTML = fromEl.innerHTML; //テキストを出力
    } else {
        toEl.href = fromEl.href; //hrefを出力
    }

    // コールバック関数を実行
    if (callback) callback(toEl);
}

export default outputAishipData;