/********************************************************************
* 商品詳細ページ　DOM操作
**********************************************************************/
import { showLoading, hideLoading } from "./../../module/loading";
import rentalDOMControl from "./rentalDOMControl";
import buyDOMControl from "./buyDOMControl";
import outputAishipData from "./../../module/outputAishipData";
import teleportElement from "./../../module/teleportElement";
import addSelectWrap from "./../../module/addSelectWrap";
import replaceAllBtn from "./../../module/replaceAllBtn";
import addScheduleClass from "./../../module/addScheduleClass";
import accordion from "./../../module/accordion";
import replaceText from "./../../module/replaceText";

const itemDOMControl = () => {
    let pageCategory = '';

    // ページカテゴリをセット
    const setPageCategory = () => {
        const target = document.querySelector('.js_detail');
        if (!target || !target.dataset.cat) return;

        const cat = target.dataset.cat;
        document.body.classList.add(cat);
        pageCategory = cat;
    }

    // 自動出力される要素を指定の場所に移動
    const outputData = () => {
        teleportElement('.sysItemImages', '.js_mv');
        outputAishipData('.sysItemName h1 span', '.js_title');
        outputAishipData('#sysDescription', '.js_description');
        outputAishipData('#sysExplanation', '.js_explanation');
        teleportElement('#sysNumber', '.js_num');
        teleportElement('#sysSelections', '.js_options');
        teleportElement('#sysSubmit', '.js_submit');
    }

    // ボタンの置換
    const replaceBtn = () => {
        const mainBtn = [
            'カートに入れる'
        ];

        replaceAllBtn(mainBtn);
    }

    // 数量のテキスト置換
    const replaceNumText = () => {
        const target = document.querySelector('#sysNumber');
        if (!target) return;

        replaceText(target, [
            {before: '：', after: ''}
        ]);
    }

    // 小計のテキスト置換
    const replaceTotal = () => {
        const observeEl = document.querySelector('.sysSubtotalPrice');
        const target = document.querySelector('.js_total');
        if (!observeEl || !target) return;

        // DOMが変更された時に発火する関数
        const doWhenDOMChanged = () => {
            const price = observeEl.textContent.match(/[0-9,]+/);
            if (!price) return;
            target.textContent = price[0];
        }

        // 小計の変更を監視
        const observer = new MutationObserver(doWhenDOMChanged);
        const config = {
            childList: true,
        };
        observer.observe(observeEl, config);
    }

    // 送料の案内を表示
    const showShippingNote = () => {
        // 商品IDを取得
        const item = document.querySelector('#sysMain > article');
        if (!item) return;
        const itemId = item.id;
        if (!itemId) return;

        // どの商品にどの案内を表示するか
        const shippingMap = {
            // 送料込み
            include: [
                'tinys-002',
                'tinys-003',
            ],
            // 送料別途
            exclude: [
                'tinyr-001',
                'tinyr-002',
                'tinys-001',
                'tinys-004',
            ]
        }

        // 送料込み表示
        const includeEl = document.querySelector('.js_shipping_include');
        if (includeEl && shippingMap.include.includes(itemId)) {
            includeEl.classList.add('is_show');
        }

        // 送料別途表示
        const excludeEl = document.querySelector('.js_shipping_exclude');
        if (excludeEl && shippingMap.exclude.includes(itemId)) {
            excludeEl.classList.add('is_show');
        }
    }

    // 関連商品の要素を追加
    const addRelated = () => {
        const dataEl = document.querySelectorAll('.js_related li');
        const target = document.querySelector('.js_related_target');

        // 親要素を非表示にする関数
        const hideWrapper = () => {
            const wrapper = document.querySelector('.js_related_target_wrap');
            if (!wrapper) return;
            wrapper.innerHTML = '';
        }

        if (!dataEl || !target) {
            hideWrapper();
            return;
        }
        
        // 関連商品があるかどうかのフラグ
        let isRelated = false;

        // 関連商品ごとに処理
        for (let i = 0; i < dataEl.length; i++) {
            const el = dataEl[i];
            const id = el.dataset.itemId;
            if (!el.dataset.itemId) continue; //空要素の場合はスキップ

            // フラグをON
            isRelated = true;
    
            // データを取得
            const linkEl = el.querySelector('a');
            const link = linkEl.href;
            const title = linkEl.textContent;
    
            // aタグを作成
            const a = document.createElement('a');
            a.href = link;
            a.classList.add('un_related_img');
    
            // imgタグを作成
            const img = document.createElement('img');
            img.src = `/img/item/banner_${id}.webp`;
            img.alt = title;
    
            // aタグにimgタグを追加
            a.appendChild(img);
    
            // targetにaタグを追加
            target.appendChild(a);
            
        }

        // フラグが立たなかった場合は親要素を削除
        if (!isRelated) {
            hideWrapper();
        }
    }

    // 売り切れかどうか判別
    const isSoldOut = () => {
        // 「数量」をチェック（optionがない場合は売り切れとする）
        const num = document.querySelector('select[name="num"]');
        return !num || num.children.length > 0 ? false : true;
    }

    // 売り切れテキストを表示
    const displaySoldOut = () => {
        // bodyにdataをセット
        document.body.setAttribute('data-soldout', 'true');
    }

    // 大枠をフォーム要素内に移動
    const teleportEltoForm = () => {
        teleportElement('.js_detail', '#sysCartInForm');
    }

    showLoading();
    setPageCategory();

    addEventListener('onSysAllContentLoaded', () => {
        outputData();
        accordion();
        addRelated();
        
        // 売り切れでない場合のみ実行
        if (isSoldOut() === false) {
            replaceNumText();
            addScheduleClass();
            replaceTotal();
            showShippingNote();
            
            // レンタル商品のみ実行
            if (pageCategory === 'rental') {
                rentalDOMControl();
            
            // 販売商品のみ実行
            } else if (pageCategory === 'buy') {
                buyDOMControl();
            }
        } else {
            displaySoldOut();
        }

        addSelectWrap();
        replaceBtn();
        teleportEltoForm();
        hideLoading();
    });

}


export default itemDOMControl;