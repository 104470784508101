/********************************************************************
* トップページ　DOM操作
**********************************************************************/
import outputAishipData from './../../module/outputAishipData';

const topDOMControl = () => {
    // データを出力
    const outputData = () => {
        // 円（税込）を削除する関数
        const removeSuffix = (el) => {
            el.textContent = el.textContent.replace('円（税込）', '');
        }
    
        outputAishipData('.js_aishipData.js_rental .sysItemPrice', '.js_rental_price', false, removeSuffix); //レンタル料金
        outputAishipData('.js_aishipData.js_rental .sysItemImage a', '.js_rental_link', true); //レンタル商品リンク
        outputAishipData('.js_aishipData.js_buy .sysItemPrice', '.js_buy_price', false, removeSuffix); //購入料金
        outputAishipData('.js_aishipData.js_buy .sysItemImage a', '.js_buy_link', true); //購入商品リンク
        outputAishipData('.js_aishipData.js_outlet .sysItemImage a', '.js_outlet_link', true); //アウトレット商品リンク
    }

    // アウトレット商品の在庫がない場合バナーを非表示にする
    const hideOutletBanner = () => {
        const dataEl = document.querySelector('.js_aishipData.js_outlet .sysItemPr');
        const target = document.querySelector('.js_outlet_link');
        if (!target || !dataEl) return;

        if (dataEl.textContent.includes('SOLDOUT')) {
            target.remove();
        }
    }


    addEventListener('onSysAllContentLoaded', () => {
        outputData();
        // hideOutletBanner();
    });
}


export default topDOMControl;