/********************************************************************
* 退会確認ページ　DOM操作(mypageDOMControl.jsから呼び出される)
**********************************************************************/
const sysMypageWithdrawalConfirmDOMControl = () => {
    const replaceAnnouncementText = () => {
        const target = document.querySelector('.sysWithdrawal p:nth-of-type(2)');
        if (!target) return;
    
        if (target.textContent.includes('退会されますと、会員登録後の「継続中」定期購入申込みも全てキャンセルされます。')) {
            target.textContent = '';
        }
    }

    replaceAnnouncementText();
}


export default sysMypageWithdrawalConfirmDOMControl;