/** ---------------------------------------------------------------------
* ローディング画面
-----------------------------------------------------------------------*/
const loading = document.querySelector('.js_loading');

/**
 * ローディング画面表示
 * */
const showLoading = () => {
    loading.classList.add('is_show');
}

/**
 * ローディング画面非表示
 * */
const hideLoading = () => {
    document.getElementById('sysMain').classList.add('is_show');
    loading.classList.remove('is_show');
}

export { showLoading, hideLoading };