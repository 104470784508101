/********************************************************************
* ログインページ　DOM操作 (mypageDOMControl.jsから呼び出される)
**********************************************************************/
import teleportElement from "./../../module/teleportElement";

const sysMypageReviewDOMControl = () => {
    const replaceBtnText = () => {
        const target = document.querySelector('input[value="会員ID・パスワードを忘れた場合"]');
        if (!target) return;
        target.value = 'パスワードを忘れた場合';
    }

    // ボタンの下に出るエラーメッセージ（完了メッセージ）を移動＆クラス付与
    const teleportErrorMsg = () => {
        const targetList = document.querySelectorAll('.bl_btnWrap + .sysErrorMessage');
        if (!targetList.length) return;

        for (const target of targetList) {
            target.classList.add('is_head');
            teleportElement(target, '.sysLoginForm', true);
        }
    }

    replaceBtnText();
    teleportErrorMsg();
}


export default sysMypageReviewDOMControl;