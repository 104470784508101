/********************************************************************
* ページタイトルを追加（user.jsから抽出後、改変）
**********************************************************************/
const addPageTitle = () => {
    $(function(){
        // 00: システムページ内にタイトル文字を入力
        $("#sysFormConfirm #sysMain").prepend("<h1 class='sysHeadline'>入力内容確認</h1>");
        $("#sysMemberRegisterConfirm #sysMain").prepend("<h1 class='sysHeadline'>入力内容確認</h1>");
        $("#sysBasket #sysMain").prepend("<h1 class='sysHeadline'>カート情報</h1>");
        $("#sysMemberRegisterIndex #sysMain").prepend("<h1 class='sysHeadline'>新規会員登録</h1>");
        $("#sysMypageLogin #sysMain").prepend("<h1 class='sysHeadline'>ログイン</h1>");
        $("#sysMypageLogout #sysMain").prepend("<h1 class='sysHeadline'>ログアウト</h1>");
        $("#sysDestination #sysMain").prepend("<h1 class='sysHeadline'>配送先の選択</h1>");
        $("#sysMypageIndex #sysMain").prepend("<h1 class='sysHeadline'>マイページ</h1>");
        $("#sysMemberRegisterRegister #sysMain").prepend("<h1 class='sysHeadline'>新規登録完了</h1>");
    });
}

export default addPageTitle;
