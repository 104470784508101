/**
 * カテゴリ一覧ページ　DOM操作
 */
import addClassBtn from '../../module/addClassBtn';

const sysItemCategoryDOMControl = () => {
    // メインエリアを空にする(運用なし想定だがページを削除できないため)
    const deleteMainArea = () => {
        const target = document.querySelector('#sysMain');
        if (!target) return;
    
        // メインエリアを空にする
        target.innerHTML = '';

        // p要素を作成
        const p = document.createElement('p');
        p.textContent = 'ページが存在しません。';
        
        // ボタンを作成
        const btn = document.createElement('a');
        addClassBtn(btn, false, true);
        btn.textContent = 'トップに戻る';
        btn.href = '/';
    
        // 要素を追加
        target.appendChild(p);
        target.appendChild(btn);

        // メインエリアを表示
        target.classList.add('is_show');
    }

    deleteMainArea();
}

export default sysItemCategoryDOMControl;