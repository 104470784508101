/**
 * ユーザーがログインしているかどうか判定
 */
const checkUserLogin = () => {
    const header = document.querySelector('.js_header_inner');
    if (!header) return;

    // 「LOGIN」を「MY PAGE」に変更
    const replaceLoginText = () => {
        const targetList = document.querySelectorAll('.js_mypageText');
        if (!targetList.length) return;
        targetList.forEach(target => target.textContent = 'MY PAGE');
    }

    // 会員登録ボタンを表示
    const showRegisterBtn = () => {
        const targetList = document.querySelectorAll('.js_register');
        if (!targetList.length) return;
        targetList.forEach(target => target.classList.add('is_show'));
    }

    addEventListener('onSysAllContentLoaded', () => {
        // ログインしている場合
        if (document.body.dataset.memberLogin === 'true') {
            replaceLoginText();
        // ログインしていない場合
        } else {
            showRegisterBtn();
        }

        header.classList.add('is_show');
    });
}

export default checkUserLogin;